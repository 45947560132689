import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import "./wallet.scss";

const Wallet = ({ modal, imgButton }) => {
  if (!process.env.GATSBY_SSP_SHOW_WALLET_PASS || process.env.GATSBY_SSP_SHOW_WALLET_PASS.toLowerCase() !== "true") {
    return null;
  }

  return (
    <div className="wallet">
      {imgButton}
      {modal && (
        <div className="wallet__info">
          <div className="wallet__label">
            <FormattedMessage id="add_to_wallet.android_user" />
          </div>
          {modal}
        </div>
      )}
    </div>
  );
};

Wallet.propTypes = {
  imgButton: PropTypes.oneOfType([PropTypes.object]).isRequired,
  modal: PropTypes.oneOfType([PropTypes.object]),
};

Wallet.defaultProps = {
  modal: null,
};

export default Wallet;
