import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import SspProfileDataCompleteData from "../sspProfileDataCompleteData/sspProfileDataCompleteData";

import "./sspProfileData.scss";

const SspProfileData = (props) => {
  const {
    header,
    emptyData,
    children,
  } = props;

  const intl = useIntl();

  return (
    <div className="ssp-profile-data">
      {header}
      {emptyData.length > 0 && (
        <SspProfileDataCompleteData
          title={intl.formatMessage({ id: "profile.complete_data.title" })}
        >
          {emptyData}
        </SspProfileDataCompleteData>
      )}
      <div className="ssp-profile-data__data">
        {children}
      </div>
    </div>
  );
};

SspProfileData.propTypes = {
  children: PropTypes.node.isRequired,
  emptyData: PropTypes.oneOfType([PropTypes.array]),
  header: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

SspProfileData.defaultProps = {
  emptyData: [],
};

export default SspProfileData;
