import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemCtaButton from "./kontentItemCtaButton";

const KontentItemSspStatusError = (props) => {
  const {
    data,
  } = props;

  const sspStatusError = Array.isArray(data) ? data : data.value;

  if (sspStatusError.length === 0) {
    return null;
  }
  const buttons = sspStatusError[0].elements.button.value.map((button) => {
    if (button.internal.type === "kontent_item_cta_button") {
      return (<KontentItemCtaButton data={button} key={button.id} />);
    }

    return <div key={button.id}>### missing element ###</div>;
  });

  return {
    buttons,
    description: sspStatusError[0].elements.description.value,
    headline: sspStatusError[0].elements.headline.value,
  };
};

export const query = graphql`
  fragment KontentItemSspStatusError on kontent_item_ssp_status_error {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      description {
        value
      }
      button {
        value {
          internal {
            type
          }
          ...KontentItemCtaButton
        }
      }
    }
  }
`;

KontentItemSspStatusError.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemSspStatusError;
