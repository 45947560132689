import React from "react";

import PropTypes from "prop-types";

import MainLayout from "../../../templates/layout/mainLayout";
import SspNavigation from "../../navigation/sspNavigation";
import SubNavigation from "../../subNavigation/subNavigation";
import SubNavigationDivider from "../../subNavigation/subNavigationDivider";
import LogoutButton from "../authentication/logoutButton";
import { defaultSspContextValue, SspContext } from "../sspContext/sspContext";

const SspLayout = ({ children }) => {
  const sspContextValue = {
    ...defaultSspContextValue,
    navigationActive: true,
  };

  return (
    <SspContext.Provider value={sspContextValue}>
      <MainLayout>

        <SubNavigation
          alignLeft
          items={
            SspNavigation({
              className: "",
            }).concat([
              <SubNavigationDivider
                key="divider"
              />,
              <LogoutButton
                key="logoutButton"
                className="sub-nav-point"
              />,
            ])
          }
        />

        {children}

      </MainLayout>
    </SspContext.Provider>
  );
};

SspLayout.propTypes = {
  children: PropTypes.node,
};

SspLayout.defaultProps = {
  children: null,
};

export default SspLayout;
