import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemCtaButton from "./kontentItemCtaButton";
import KontentItemImage from "./kontentItemImage";
import ImageLinkTile from "../components/imageLinkTile/imageLinkTile";
import DataLayer from "../utils/dataLayer";
import getInternalLinkById from "../utils/getInternalLinkById";
import { kebabCase } from "../utils/stringUtilHelper";

const KontentItemImageLinkTiles = ({ apiRole, data, taskKey }) => {
  const imageLinkTile = Array.isArray(data) ? data : data.value;

  const imageLinkTileList = [];
  let eventValue;

  imageLinkTile.forEach((tile) => {
    if (tile.elements.button_url.value.length > 0) {
      eventValue = getInternalLinkById(tile.elements.button_url.value[0].id).slug;
    }

    imageLinkTileList.push(
      <ImageLinkTile
        key={tile.id}
        image={<KontentItemImage data={tile.elements.image} aspectRatio={21 / 9} />}
        label={tile.elements.label.value}
        buttonText={tile.elements.button_text.value}
        link={tile.elements.button_url.value.length > 0
          ? tile.elements.button_url.value[0].id
          : null}
        variant={tile.elements.variant.value.length > 0
          ? kebabCase(tile.elements.variant.value[0].codename)
          : null}
        onClick={() => {
          DataLayer.pushEvent("pp_ssp_sidebar_click", {
            dimension_ssp_role: apiRole,
            dimension_ssp_status: taskKey,
            event_value: eventValue,
          });
        }}
        button={tile.elements.cta_button.value.length > 0
          ? <KontentItemCtaButton data={tile.elements.cta_button.value[0]} />
          : null}
      />,
    );
  });

  return React.Children.toArray(imageLinkTileList);
};

export const query = graphql`
  fragment KontentItemImageLinkTiles on kontent_item_image_link_tile {
    id
    system {
      codename
    }
    elements {
      button_text {
        value
      }
      label {
        value
      }
      variant {
        value {
          codename
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
      button_url {
        value {
          id
        }
      }
      cta_button {
        value {
          ...KontentItemCtaButton
        }
      }
    }
  } 
`;

KontentItemImageLinkTiles.propTypes = {
  apiRole: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  taskKey: PropTypes.string.isRequired,
};

export default KontentItemImageLinkTiles;
