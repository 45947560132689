import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContentHeader from "../../components/contentHeader/contentHeader";
import SEO from "../../components/seo";
import { HeaderIconAccountNoAccess } from "../../images";
import KontentItemCtaButton from "../kontentItemCtaButton";
import MainLayout from "../layout/mainLayout";

const MaintenanceMode = () => {
  const { kontentItemErrorPage } = useStaticQuery(graphql`
    {
      kontentItemErrorPage(elements: {error_type: {value: {elemMatch: {codename: {eq: "ssp_maintenance"}}}}}) {
        id
        system {
          codename
        }
        elements {
          headline {
            value
          }
          text {
            value
          }
          button {
            value {
              ...KontentItemCtaButton
            }
          }
        }
      }
    }
  `);

  const intl = useIntl();

  return (
    <MainLayout>
      <SEO title={intl.formatMessage({ id: "maintenance_mode_page.title" })} />

      <Breadcrumb items={[{ title: intl.formatMessage({ id: "maintenance_mode_page.title" }) }]} />

      <ContentHeader
        headline={kontentItemErrorPage.elements.headline.value}
        description={kontentItemErrorPage.elements.text.value}
        button={(
          <>
            {
              kontentItemErrorPage.elements.button.value.length > 0
                ? kontentItemErrorPage.elements.button.value.map((item) => (
                  <KontentItemCtaButton
                    key={item.id}
                    data={item}
                  />
                ))
                : null
            }
          </>
        )}
        media={<HeaderIconAccountNoAccess />}
      />
    </MainLayout>
  );
};

export default MaintenanceMode;
