import React from "react";

import PropTypes from "prop-types";

import "./sspRegistry.scss";

const SspRegistry = ({ header, children }) => (
  <div className="ssp-registry">
    {header}
    {children}
  </div>
);

SspRegistry.propTypes = {
  children: PropTypes.node,
  header: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

SspRegistry.defaultProps = {
  children: null,
};

export default SspRegistry;
