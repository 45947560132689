import React from "react";

import PropTypes from "prop-types";

import "./headlineElement.scss";

const HeadlineElement = (props) => {
  const {
    children,
    title,
  } = props;

  return (
    <div className="headline-element">
      <h3>{title}</h3>
      {children && (
        <div className="headline-element-description">
          {children}
        </div>
      )}
    </div>
  );
};

HeadlineElement.propTypes = {
  children: PropTypes.string,
  title: PropTypes.string.isRequired,
};

HeadlineElement.defaultProps = {
  children: null,
};

export default HeadlineElement;
