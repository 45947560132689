import React from "react";

import { useSelector } from "react-redux";

import useEventListener from "../../../hooks/haiku/useEventListener";
import { isSelfServicePortalEnabled } from "../../../services/selfServicePortal.service";
import { isAuthenticated } from "../../../services/sspAuthentication.service";
import MaintenanceModePage from "../../../templates/pages/maintenanceModePage";
import NoAccessPage from "../../../templates/pages/noAccessPage";

// eslint-disable-next-line react/display-name
const withAuthentication = (WrappedComponent) => (props) => {
  if (typeof window === "undefined") {
    return null;
  }

  if (!isSelfServicePortalEnabled()) {
    return MaintenanceModePage();
  }

  const pseudoLoggedOut = useSelector((state) => state.ssp.pseudoLoggedOut);

  // prevents the bfcache to render the page even when the user is not authenticated in the api
  useEventListener("pageshow", (event) => {
    if (event.persisted) {
      // eslint-disable-next-line no-restricted-globals
      window.location.reload();
    }
  });

  if (!isAuthenticated() || pseudoLoggedOut) {
    return NoAccessPage();
  }

  return <WrappedComponent {...props} />;
};

export default withAuthentication;
