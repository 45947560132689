import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./subNavigationDivider.scss";

const SubNavigationDivider = ({ className }) => (
  <div className={classNames("subnavigation__divider", className)} />
);

SubNavigationDivider.propTypes = {
  className: PropTypes.string,
};

SubNavigationDivider.defaultProps = {
  className: "",
};

export default SubNavigationDivider;
