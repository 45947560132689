import React, { memo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./shimmer.scss";

const Shimmer = (props) => {
  const {
    variant,
    width,
    height,
    className,
  } = props;

  if (width && height) {
    return (
      <div className={classNames("shimmer", className)} style={{ height: `${height}px`, maxWidth: `${width}px` }} />
    );
  }

  return (
    <div className={classNames("shimmer", `shimmer--${variant}`, className)} />
  );
};

Shimmer.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  variant: PropTypes.oneOf(["box", "textarea", "headline", "text"]),
  width: PropTypes.number,
};

Shimmer.defaultProps = {
  className: null,
  height: null,
  variant: "text",
  width: null,
};

export default memo(Shimmer);
