import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { kebabCase } from "../../utils/stringUtilHelper";

import "./registrationStatus.scss";

const RegistrationStatus = (props) => {
  const {
    headline,
    description,
    icon,
    variant,
  } = props;

  return (
    <div className={classNames("registration-status", { [`registration-status--${kebabCase(variant)}`]: variant })}>
      <div className="registration-status__headline">{icon} {headline}</div>
      {description && <div className="registration-status__description">{description}</div>}
    </div>
  );
};

RegistrationStatus.propTypes = {
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]),
  variant: PropTypes.oneOf(["kit_requester ", "user", "donor"]),
};

RegistrationStatus.defaultProps = {
  description: null,
  icon: null,
  variant: null,
};

export default RegistrationStatus;
