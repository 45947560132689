import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemModalView from "./kontenItemModalView";
import KontentItemImageButton from "./kontentItemImageButton";
import KontentItemStatusTask from "./kontentItemStatusTask";
import DonorCard from "../components/donorCard/donorCard";
import StatusTask from "../components/statusTask/statusTask";
import { IconMap24, IconMessage24 } from "../icons";
import {
  TASKKEY_AVAILABLE,
  TASKKEY_CREATED,
  TASKKEY_PLACEHOLDER,
  TASKKEY_REQUESTED,
  TASKKEY_SAMPLE_LABORATORY_ENTRY,
  TASKKEY_SAMPLE_PROVIDED,
  TASKKEY_SWABKIT_ORDERED,
} from "../utils/getTaskKey";
import { ROLE_DONOR, ROLE_KIT_REQUESTER, ROLE_USER } from "../utils/getUserRole";
import iconHelper from "../utils/iconHelper";

const KontentItemSspRole = (props) => {
  const {
    data,
    apiRole,
    activeTask,
    user,
    regStatus,
  } = props;

  const role = data.value ? data.value[0] : data;

  if (!role || !role.elements) {
    return null;
  }

  let tasks = {};
  let isTaskCompleted = true;

  const keys = {
    [ROLE_KIT_REQUESTER]: [TASKKEY_SWABKIT_ORDERED, TASKKEY_SAMPLE_PROVIDED, `${TASKKEY_PLACEHOLDER}3`, TASKKEY_SAMPLE_LABORATORY_ENTRY, `${TASKKEY_PLACEHOLDER}5`],
    [ROLE_USER]: [TASKKEY_CREATED, TASKKEY_REQUESTED, TASKKEY_AVAILABLE],
  };

  if (apiRole !== ROLE_DONOR) {
    for (let i = 1; i < keys[apiRole].length + 1; i += 1) {
      tasks[keys[apiRole][i - 1]] = KontentItemStatusTask(
        {
          apiRole,
          data: role.elements[`statustask_0${i}`],
          key: `statustask_0${i}`,
          success: isTaskCompleted,
          taskKey: regStatus,
        },
      );

      if (keys[apiRole][i - 1] === activeTask) {
        isTaskCompleted = false;
      }
    }
  } else {
    tasks = {
      address: <StatusTask
        key="task1"
        icon={<div className="text-registration"><IconMap24 /></div>}
        description={(
          <div>
            <div>{user?.address?.street} {user?.address?.houseno}</div>
            <div>{user?.address?.zipcode} {user?.address?.city}</div>
          </div>
        )}
      />,
      personalContactData: <StatusTask
        key="task2"
        icon={<div className="text-registration"><IconMessage24 /></div>}
        description={(
          <div>
            <div>{user?.email}</div>
            {user?.mobile && <div>{user?.mobile}</div>}
            {user?.phone && <div>{user?.phone}</div>}
          </div>
        )}
      />,
      registered: KontentItemStatusTask({
        apiRole,
        data: role.elements.statustask_01,
        taskKey: activeTask,
      }),
    };
  }

  if (apiRole === ROLE_DONOR) {
    return ({
      AddToWalletButton: <KontentItemImageButton data={role.elements.add_to_wallet_button} />,
      description: role.elements.description.value,
      donorcard: <DonorCard donorId={user?.donorId} name={user?.name} />,
      icon: iconHelper(role.elements.icon.value.length > 0
        ? role.elements.icon.value[0].codename
        : null),
      modal: <KontentItemModalView data={role.elements.modal} />,
      role: role.elements.role.value,
      statusTasks: tasks,
    });
  }

  return (
    {
      description: role.elements.description.value,
      icon: iconHelper(role.elements.icon.value.length > 0
        ? role.elements.icon.value[0].codename
        : null),
      role: role.elements.role.value,
      statusTasks: tasks,
    }
  );
};

export const query = graphql`
  fragment KontentItemSspRole on kontent_item_ssp_role {
    id
    system {
      codename
    }
    elements {
      description {
        value
      }
      icon {
        value {
          codename
        }
      }
      role {
        value
      }
      statustask_01 {
        value {
          ...KontentItemStatusTask
        }
      }
      statustask_02 {
        value {
          ...KontentItemStatusTask
        }
      }
      statustask_03 {
        value {
          ...KontentItemStatusTask
        }
      }
      statustask_04 {
        value {
          ...KontentItemStatusTask
        }
      }
      statustask_05 {
        value {
          ...KontentItemStatusTask
        }
      }
      modal {
        value {
          ...KontentItemModalView
        }
      }
      add_to_wallet_button {
        value {
          ...KontentItemImageButton
        }
      }
    }
  } 
`;

KontentItemSspRole.propTypes = {
  activeTask: PropTypes.string.isRequired,
  apiRole: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  regStatus: PropTypes.string,
  user: PropTypes.oneOfType([PropTypes.object]),
};

KontentItemSspRole.defaultProps = {
  regStatus: "",
  user: null,
};

export default KontentItemSspRole;
