import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from "react-redux";

import ProfileData from "./profileData";
import ProfileDataHelper from "./profileDataHelper";
import profileValidationSchema from "./profileValidationSchema";
import SspProfileData from "./sspProfileData/sspProfileData";
import SspProfileDataElement from "./sspProfileDataElement/sspProfileDataElement";
import { useChangePasswordMutation } from "../../features/io/ioSspApiSlice";
import { useSubmitIoUpdateAddressMutation } from "../../features/io/ioUpdateAddressApiSlice";
import { clearIsEditing, setIsEditing, setIsEditingForFailedSegments } from "../../features/sspSlice";
import useEventListener from "../../hooks/haiku/useEventListener";
import {
  IconAccordionExpand18,
  IconClose18,
  IconClose24,
  IconError18,
  IconLearnMore18,
  IconRetry24,
  IconSave18,
  IconSave24,
} from "../../icons";
import { notificationService } from "../../services/notification.service";
import DataLayer from "../../utils/dataLayer";
import { format } from "../../utils/dateHelper";
import { trimObjectValues } from "../../utils/formik/objectUtils";
import AdaptiveIcon from "../adaptiveIcon";
import AddressInput from "../addressInput/addressInput";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import InputSelect from "../inputSelect/inputSelect";
import Notification from "../notification/notification";

const ProfileDataFormBuilder = (props) => {
  const {
    profileInfo,
    isNotRegistered,
    header,
    apiRole,
    taskKey,
  } = props;

  const intl = useIntl();

  const [changePassword, changePasswordResult] = useChangePasswordMutation();
  const [submitIoUpdateAddress, submitIoUpdateAddressResult] = useSubmitIoUpdateAddressMutation();

  const isEditing = useSelector((state) => state.ssp.isEditing);
  const dispatch = useDispatch();
  const [buttonOutOfView, setButtonOutOfView] = useState(false);
  const [password, setPassword] = useState(false);
  const updateDetailsNotification = "update-details-notification";
  const passwordNotification = "password-notification";

  const buttonSave = useRef();

  const profileData = JSON.parse(sessionStorage.getItem("sessionProfileData")) || new ProfileData();

  const profile = JSON.parse(sessionStorage.getItem("sessionProfile")) || ProfileDataHelper.getProfileInformation(profileInfo, profileData);

  const error = ProfileDataHelper.requiredDataAvailable(profileData);

  const excludedFields = ["co", "apartment", "title", "salutation"];

  const calculateButton = () => {
    if (buttonSave && buttonSave.current) {
      const objectHeight = buttonSave.current.offsetHeight;
      const positionTop = buttonSave.current.getBoundingClientRect().top;
      const positionBottom = positionTop + objectHeight;

      if (!buttonOutOfView && (positionBottom <= 0 || positionTop >= window.innerHeight)) {
        setButtonOutOfView(true);
      } else if (buttonOutOfView && (positionBottom > 0 && positionTop < window.innerHeight)) {
        setButtonOutOfView(false);
      }
    }
  };

  useEventListener("scroll", calculateButton, null, { passive: true });
  useEventListener("resize", calculateButton);

  useEffect(() => () => {
    dispatch(
      clearIsEditing(),
    );
  }, []);

  useEffect(() => {
    calculateButton();
  }, [isEditing]);

  useEffect(() => {
    if (changePasswordResult.isError) {
      notificationService.clear(passwordNotification);
      notificationService.error(
        intl.formatMessage({ id: "profile.password_error_message" }, { value: changePasswordResult.error?.data?.detail?.error || "" }),
        {
          action: (
            <LinkButton
              onClick={() => {
                changePassword({ new_password: password });
              }}
            >
              <FormattedMessage id="ssp.login_error.retry" /> <IconRetry24 />
            </LinkButton>),
          autoClose: false,
          dismissButtonText: <FormattedMessage id="ssp.login_error.hide" />,
          id: passwordNotification,
        },
      );

      DataLayer.pushEvent("pp_ssp_pwd_update", {
        dimension_ssp_role: apiRole,
        dimension_ssp_status: taskKey,
        event_value: "fail",
      });
    }

    if (changePasswordResult.isSuccess) {
      notificationService.clear(passwordNotification);
      notificationService.success(
        intl.formatMessage({ id: "profile.password_success_message" }),
        {
          dismissButtonText: <FormattedMessage id="ssp.login_error.hide" />,
          id: passwordNotification,
        },
      );
      DataLayer.pushEvent("pp_ssp_pwd_update", {
        dimension_ssp_role: apiRole,
        dimension_ssp_status: taskKey,
        event_value: "success",
      });
    }
  }, [changePasswordResult]);

  useEffect(() => {
    if (submitIoUpdateAddressResult.isError) {
      notificationService.clear(updateDetailsNotification);
      notificationService.error(
        intl.formatMessage({ id: "profile.details_error_message" }, { value: changePasswordResult.error?.data?.detail?.error || "" }),
        {
          action: (
            <LinkButton
              onClick={() => {
                changePassword({ new_password: password });
              }}
            >
              <FormattedMessage id="ssp.login_error.retry" /> <IconRetry24 />
            </LinkButton>),
          autoClose: false,
          dismissButtonText: <FormattedMessage id="ssp.login_error.hide" />,
          id: updateDetailsNotification,
        },
      );

      DataLayer.pushEvent("pp_ssp_ciu_update", {
        dimension_ssp_role: apiRole,
        dimension_ssp_status: taskKey,
        event_value: "fail",
      });
    }

    if (submitIoUpdateAddressResult.isSuccess) {
      notificationService.clear(updateDetailsNotification);
      notificationService.success(
        intl.formatMessage({ id: "profile.details_success_message" }),
        {
          dismissButtonText: <FormattedMessage id="ssp.login_error.hide" />,
          id: updateDetailsNotification,
        },
      );
      dispatch(
        clearIsEditing(),
      );

      DataLayer.pushEvent("pp_ssp_ciu_update", {
        dimension_ssp_role: apiRole,
        dimension_ssp_status: taskKey,
        event_value: "success",
      });
    }
  }, [submitIoUpdateAddressResult]);

  const getEmptyData = () => {
    const emptyData = [];

    Object.keys(profile).forEach((item) => {
      Object.keys(profile[item]).forEach((key) => {
        if (!(excludedFields.indexOf(key) !== -1) && !profile[item][key]) {
          emptyData.push(
            <a
              className="icon-btn btn btn-inverted-primary"
              href={`#${item}`}
              key={key}
              onClick={() => {
                dispatch(
                  setIsEditing(item),
                );
              }}
            >
              <IconAccordionExpand18 /> {intl.formatMessage({ id: `profile.${key}.label` })}
            </a>,
          );
        }
      });
    });

    return emptyData;
  };

  const checkValues = (a, b) => Object.keys(a).find((field) => field !== "password" && a[field] !== b[field]);

  const getFields = (
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  ) => {
    const titleOptions = ProfileDataHelper.getTitleOptions();

    const getOptionByValue = (options, value) => options.find((el) => el.value === value);

    const dataBlocks = [];

    if (isNotRegistered) {
      delete profile.private_address;
      delete profile.alternatePhone;
      delete profile.phone;
      delete profile.username;
    }

    if (isEditing.length > 0 && errors && Object.keys(errors).length > 0) {
      const segmentsWithError = Object.keys(profile).filter(
        (key) => Object.keys(profile[key]).filter(
          (element) => Object.keys(errors).indexOf(element) !== -1,
        ).length > 0,
      );

      dispatch(
        setIsEditingForFailedSegments(segmentsWithError),
      );
    }

    Object.keys(profile).forEach((item) => {
      let formFields = null;
      let additionalContent = null;
      let labelIcon = null;

      switch (item) {
        case "private_address":
          additionalContent = (
            <AddressInput
              onResult={(data) => {
                setFieldValue("street", data.street || "");
                setFieldValue("houseno", data.houseno || "");
                setFieldValue("zipcode", data.zipcode || "");
                setFieldValue("city", data.city || "");
              }}
            />
          );
          break;
        case "username":
          labelIcon = (
            <OverlayTrigger
              placement="top"
              delay={{ hide: 400, show: 100 }}
              overlay={(param) => (
                <Tooltip {...param}>
                  <FormattedMessage id="profile.username_info" />
                </Tooltip>
              )}
            >
              <IconLearnMore18 className="text-grey-dark" />
            </OverlayTrigger>
          );
          break;
        default:
          labelIcon = null;
      }

      if (isEditing.indexOf(item) !== -1) {
        formFields = (
          <Row>
            {Object.keys(profile[item]).map((key) => {
              let colProps = {
                sm: 6,
              };

              if (key === "street" || key === "city") {
                colProps = {
                  sm: 8,
                };
              } else if (key === "houseno" || key === "zipcode") {
                colProps = {
                  sm: 4,
                };
              }

              switch (key) {
                case "salutation":
                  return (
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Check
                          type="radio"
                          label={intl.formatMessage({ id: "form.sex.male" })}
                          name={key}
                          value="M"
                          id="gender-male"
                          checked={values[key] === "Herr"}
                          disabled
                          inline
                          className="mb-0"
                        />
                        <Form.Check
                          type="radio"
                          label={intl.formatMessage({ id: "form.sex.female" })}
                          name={key}
                          value="F"
                          id="gender-female"
                          checked={values[key] === "Frau"}
                          disabled
                          inline
                          className="mb-0"
                        />
                        <OverlayTrigger
                          placement="top"
                          delay={{ hide: 400, show: 100 }}
                          overlay={(param) => (
                            <Tooltip {...param}>
                              <FormattedMessage id="profile.gender_info" />
                            </Tooltip>
                          )}
                        >
                          <IconLearnMore18 className="text-grey-dark" />
                        </OverlayTrigger>
                        {errors.gender && touched.gender && (
                          <Form.Text className="invalid-feedback">
                            <IconError18 />{errors.gender}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  );
                case "title":
                  return (
                    <Col key={key} xs={12}>
                      <Form.Group className="w-sm-50">
                        <InputSelect
                          placeholder={intl.formatMessage({ id: "form.please_select" })}
                          items={titleOptions}
                          name={key}
                          value={getOptionByValue(titleOptions, values[key])}
                          onChange={(selectedOption) => {
                            if (selectedOption && selectedOption.value) {
                              setFieldValue(key, selectedOption.value);
                              setFieldError(key, undefined);
                            } else {
                              setFieldValue(key, "");
                            }
                          }}
                          onBlur={() => {
                            setFieldTouched(key, true);
                          }}
                          isInvalid={(errors[key] && touched[key])}
                        />
                        <Form.Label>
                          <FormattedMessage id={`profile.${key}.label`} />
                        </Form.Label>
                        <Form.Text className={classNames({ "invalid-feedback": errors[key] && touched[key] })}>
                          {errors[key] && touched[key] && (
                            <>, <IconError18 />{errors[key]}</>
                          )}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  );
                case "password":
                  return (
                    <>
                      <Col key={key} {...colProps}>
                        <Form.Group className="required">
                          <Form.Control
                            type="password"
                            placeholder={intl.formatMessage({ id: `profile.${key}.label` })}
                            name={key}
                            value={values[key]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={(errors[key] && touched[key])}
                            maxLength={40}
                          />
                          <Form.Label>
                            <FormattedMessage id={`profile.${key}.label`} />
                          </Form.Label>
                          {errors[key] && touched[key] && (
                            <Form.Text className="invalid-feedback">
                              <IconError18 />{errors[key]}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col key="confirmPassword" {...colProps}>
                        <Form.Group className="required">
                          <Form.Control
                            type="password"
                            placeholder={intl.formatMessage({ id: `profile.confirm_${key}.label` })}
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={(errors.confirmPassword && touched.confirmPassword)}
                            maxLength={40}
                          />
                          <Form.Label>
                            <FormattedMessage id={`profile.confirm_${key}.label`} />
                          </Form.Label>
                          {errors.confirmPassword && touched.confirmPassword && (
                            <Form.Text className="invalid-feedback">
                              <IconError18 />{errors.confirmPassword}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        {/* eslint-disable-next-line react/no-danger */}
                        <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "profile.password_notice" }) }} />
                      </Col>
                    </>
                  );
                case "email":
                  return (
                    <Col key={key} {...colProps}>
                      <Form.Group className="required">
                        <Form.Control
                          type="text"
                          name={key}
                          value={values[key]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values[key]}
                          isInvalid={(errors[key] && touched[key])}
                          maxLength={40}
                        />
                        <Form.Label>
                          <FormattedMessage id={`profile.${key}.label`} />
                        </Form.Label>
                        <Form.Text>
                          <FormattedMessage id={`profile.info.${key}`} />
                        </Form.Text>
                        {errors[key] && touched[key] && (
                          <Form.Text className="invalid-feedback">
                            <IconError18 />{errors[key]}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  );
                default:
                  return (
                    <Col key={key} {...colProps}>
                      <Form.Group
                        className={classNames({
                          required: !["apartment", "co"].includes(key),
                        })}
                      >
                        <Form.Control
                          type="text"
                          name={key}
                          value={values[key]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          defaultValue={values[key]}
                          isInvalid={(errors[key] && touched[key])}
                          maxLength={40}
                        />
                        <Form.Label>
                          <FormattedMessage id={`profile.${key}.label`} />
                        </Form.Label>
                        {errors[key] && touched[key] && (
                          <Form.Text className="invalid-feedback">
                            <IconError18 />{errors[key]}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  );
              }
            })}
          </Row>
        );
      } else {
        Object.keys(profile[item]).forEach((key) => {
          if (values[key] !== profileData[key]) {
            setFieldValue(key, profileData[key]);
          }
        });
      }

      dataBlocks.push(
        <SspProfileDataElement
          id={`${item}`}
          label={intl.formatMessage({ id: `profile.${item}` })}
          labelIcon={labelIcon}
          data={profile[item]}
          form={formFields}
          additionalContent={additionalContent}
          isNotRegistered={isNotRegistered}
          error={error}
        />,
      );
    });

    return dataBlocks;
  };

  return (
    <SspProfileData header={header} emptyData={!isNotRegistered && !error ? getEmptyData() : []}>
      <Formik
        initialValues={profileData}
        validationSchema={profileValidationSchema(intl)}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          if (values.password) {
            setPassword(values.password);
            changePassword({ new_password: values.password });
          }

          // todo: remove me start
          const tmpSessionProfile = {
            ...profileInfo,
            ...{
              apartment: values?.apartment,
              birthdate: values?.birthdate,
              city: values?.city,
              co: values?.co,
              donor_id: values?.donorId,
              email: values?.email,
              firstname: values?.firstname,
              houseno: values?.houseno,
              lastname: values?.lastname,
              mobile: values?.mobile,
              phone: values?.alternatePhone,
              salutation: values?.salutation,
              sex: values?.gender,
              street: values?.street,
              title: values?.title,
              zipcode: values?.zipcode,
            },
          };

          delete tmpSessionProfile.password;

          sessionStorage.setItem("sessionProfile", JSON.stringify(ProfileDataHelper.getProfileInformation(tmpSessionProfile, {}), (k, v) => (v === undefined ? "" : v)));
          sessionStorage.setItem("sessionProfileData", JSON.stringify(values, (k, v) => (v === undefined ? "" : v)));
          // todo: remove me end

          if (checkValues(values, profileData)) {
            submitIoUpdateAddress({
              data: ProfileDataHelper.preparePayload(trimObjectValues(values), profileData, format(new Date(), "yyyy-MM-dd")),
              urn: process.env.GATSBY_IO_URN_CIU_SSP,
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            {getFields(
              values,
              errors,
              touched,
              isValid,
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldError,
              setFieldTouched,
            )}
            {isEditing.length > 0 && (
              <div className="d-sm-flex">
                <Form.Group
                  className="mr-sm-4 mb-4 mb-sm-0"
                  ref={buttonSave}
                >
                  <IconButton
                    variant="registration"
                    type="submit"
                    className={classNames({ "button--sticky": buttonOutOfView })}
                    onClick={() => {
                      const err = Object.keys(errors);

                      if (err.length) {
                        const input = document.querySelector(
                          `input[name=${err[0]}]`,
                        );

                        if (input) {
                          input.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "start",
                          });
                        }
                      }
                    }}
                  >
                    <AdaptiveIcon
                      sm={<IconSave18 />}
                      lg={<IconSave24 />}
                    />
                    <FormattedMessage id="profile.button_submit" />
                  </IconButton>
                </Form.Group>
                <Form.Group>
                  <IconButton
                    type="button"
                    variant="inverted-registration"
                    onClick={() => {
                      dispatch(
                        clearIsEditing(),
                      );
                    }}
                    disabled={isSubmitting}
                  >
                    <AdaptiveIcon
                      sm={<IconClose18 />}
                      lg={<IconClose24 />}
                    />
                    <FormattedMessage id="profile.button_discard" />
                  </IconButton>
                </Form.Group>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <div className="notifications">
        <Notification id={passwordNotification} />
        <Notification id={updateDetailsNotification} />
      </div>
    </SspProfileData>
  );
};

ProfileDataFormBuilder.propTypes = {
  accountSettingsHref: PropTypes.string,
  apiRole: PropTypes.string.isRequired,
  header: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isNotRegistered: PropTypes.bool.isRequired,
  profileInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  taskKey: PropTypes.string.isRequired,
};

ProfileDataFormBuilder.defaultProps = {
  accountSettingsHref: null,
};

export default ProfileDataFormBuilder;
