import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { IconFurther18, IconFurther24 } from "../../icons";
import AdaptiveIcon from "../adaptiveIcon";
import IconButton from "../button/iconButton";

import "./imageLinkTile.scss";

const ImageLinkTile = (props) => {
  const {
    buttonText,
    image,
    label,
    link,
    variant,
    onClick,
    button,
  } = props;

  return (
    <div className="image-link-tile">
      {image}
      <div className={classNames("image-link-tile__content", [`bg-${variant}-dualtone`])}>
        <div className="image-link-tile__label">{label}</div>
        {button || (
          <IconButton onClick={onClick} href={link} variant={`plain-${variant}`}>
            {buttonText}
            <AdaptiveIcon
              sm={<IconFurther18 />}
              lg={<IconFurther24 />}
            />
          </IconButton>
        )}
      </div>
    </div>
  );
};

ImageLinkTile.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  buttonText: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    "registration",
    "donation",
    "learn-area",
    "events",
    "faq",
  ]),
};

ImageLinkTile.defaultProps = {
  button: null,
  onClick: () => {},
  variant: "registration",
};

export default ImageLinkTile;
