import React, { memo } from "react";

import PropTypes from "prop-types";

import "./sspTitle.scss";

const SspTitle = ({ headline, icon }) => (
  <div className="ssp-title">
    {icon}<h2>{headline}</h2>
  </div>
);

SspTitle.propTypes = {
  headline: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default memo(SspTitle);
