import React from "react";

import PropTypes from "prop-types";

import { IconWarning24 } from "../../icons";

import "./statusBox.scss";

const StatusBox = (props) => {
  const {
    donorCard,
    description,
    statusTasks,
    buttons,
    errorData,
    errorState,
    addToWallet,
  } = props;

  const buttonsToRender = errorState ? errorData.buttons : buttons;

  return (
    <div className="status-box">
      {errorState
        ? <div className="status-box__header"><IconWarning24 />{errorData.headline}</div>
        : (
          <div className="status-box__content">
            {donorCard && (
              <div className="status-box__donor-card-container">
                {donorCard}
                {addToWallet
                  && <div className="d-flex align-items-end">{addToWallet}</div>}
              </div>
            )}
            {statusTasks}
          </div>
        )}
      <div className="typo-body1">{errorState ? errorData.description : description}</div>
      <div className="status-box__button-container">
        {buttonsToRender.map((button) => (
          <div key={button.key}>{button}</div>
        ))}
      </div>
    </div>
  );
};

StatusBox.propTypes = {
  addToWallet: PropTypes.oneOfType([PropTypes.object]),
  buttons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  donorCard: PropTypes.oneOfType([PropTypes.object]),
  errorData: PropTypes.shape({
    buttons: PropTypes.oneOfType([PropTypes.array]).isRequired,
    description: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
  }),
  errorState: PropTypes.bool,
  statusTasks: PropTypes.oneOfType([PropTypes.array]),
};

StatusBox.defaultProps = {
  addToWallet: null,
  buttons: null,
  donorCard: null,
  errorData: null,
  errorState: false,
  statusTasks: null,
};

export default StatusBox;
