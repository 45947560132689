import React from "react";

import PropTypes from "prop-types";

import {
  IconCalendarArchive18,
  IconCheck18,
  IconError18,
  IconTime18,
} from "../../icons";
import FilterTag from "../filterTag/filterTag";

import "./countrySpecification.scss";

const CountrySpecification = (props) => {
  const {
    label,
    icon,
    status,
    statusText,
  } = props;

  let filterTag;

  switch (status) {
    case "active":
      filterTag = (
        <FilterTag icon={<IconCheck18 />} variant={status}>
          {statusText}
        </FilterTag>
      );
      break;
    case "progress":
      filterTag = (
        <FilterTag icon={<IconTime18 />} variant={status}>
          {statusText}
        </FilterTag>
      );
      break;
    case "paused":
      filterTag = (
        <FilterTag icon={<IconCalendarArchive18 />} variant={status}>
          {statusText}
        </FilterTag>
      );
      break;
    case "error":
      filterTag = (
        <FilterTag icon={<IconError18 />} variant={status}>
          {statusText}
        </FilterTag>
      );
      break;
    default:
      filterTag = null;
  }

  return (
    <div className="country-specification">
      <div className="country-specification__label">
        {icon}{label}
      </div>
      <div>
        {filterTag}
      </div>
    </div>
  );
};

CountrySpecification.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.oneOf(["active", "paused", "progress"]),
  statusText: PropTypes.string.isRequired,
};

CountrySpecification.defaultProps = {
  status: null,
};

export default CountrySpecification;
