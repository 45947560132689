import React, { useState } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { IconEyeHide24, IconEyeShow24 } from "../../icons";
import { DkmsNotaglineWhiteS } from "../../images";

import "./donorCard.scss";

const DonorCard = ({ name, donorId }) => {
  const [visibleId, setVisibleId] = useState(false);

  const tmpId = visibleId ? donorId : `${donorId.substr(0, 5)}✱✱✱✱✱✱✱✱`;

  return (
    <div className="donor-card">
      <DkmsNotaglineWhiteS />
      <div className="donor-card__name">{name}</div>
      <div className="donor-card__id-text">
        <FormattedMessage id="donor_card.donor_id" />
      </div>
      <div className="donor-card__id">
        {tmpId}
        <OverlayTrigger
          placement="top"
          delay={{ hide: 400, show: 100 }}
          overlay={(param) => (
            <Tooltip {...param}>
              <FormattedMessage id="donor_card.id_info" />
            </Tooltip>
          )}
        >
          {visibleId ? (
            <IconEyeHide24
              onClick={() => {
                setVisibleId(false);
              }}
            />
          ) : (
            <IconEyeShow24
              onClick={() => {
                setVisibleId(true);
              }}
            />
          )}
        </OverlayTrigger>
      </div>
    </div>
  );
};

DonorCard.propTypes = {
  donorId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default DonorCard;
