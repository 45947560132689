import React from "react";

import PropTypes from "prop-types";

import "./statusTask.scss";

const StatusTask = (props) => {
  const {
    icon,
    description,
  } = props;

  return (
    <div className="status-task">
      {icon} {description}
    </div>
  );
};

StatusTask.propTypes = {
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default StatusTask;
