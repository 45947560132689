import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { IconLearnMore18 } from "../../../icons";

import "./sspProfileDataCompleteData.scss";

const SspProfileDataCompleteData = ({ title, children }) => (
  <div className="ssp-profile-data-complete-data">
    <div className="ssp-profile-data-complete-data__title">
      {title}
      <OverlayTrigger
        placement="top"
        delay={{ hide: 400, show: 100 }}
        overlay={(param) => (
          <Tooltip {...param}>
            <FormattedMessage id="profile.complete_data_info" />
          </Tooltip>
        )}
      >
        <IconLearnMore18 />
      </OverlayTrigger>
    </div>
    <div className="ssp-profile-data-complete-data__content">
      {children}
    </div>
  </div>
);

SspProfileDataCompleteData.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

SspProfileDataCompleteData.defaultProps = {
  children: null,
};

export default SspProfileDataCompleteData;
