import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../../hooks/useWindowDimensions";

import "./sspSidebarLayout.scss";

const SspSidebarLayout = (props) => {
  const {
    children,
    sidebarElements,
    dashboard,
    areas,
  } = props;

  const { width } = useWindowDimensions();

  return (
    <Row className={classNames("ssp-sidebar-layout", { "ssp-sidebar-layout--dashboard": dashboard })}>
      <Col lg={7}>
        {children}
        {width >= 992 && areas }
      </Col>

      {sidebarElements && (
        <Col lg={{ offset: 1, span: 4 }} className="ssp-sidebar-layout__sidebar">
          {sidebarElements}
        </Col>
      )}

      {areas && width < 992 && (
        <Col xs={12}>
          {areas}
        </Col>
      )}
    </Row>
  );
};

SspSidebarLayout.propTypes = {
  areas: PropTypes.oneOfType([PropTypes.object]),
  children: PropTypes.node.isRequired,
  dashboard: PropTypes.bool,
  sidebarElements: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SspSidebarLayout.defaultProps = {
  areas: null,
  dashboard: false,
  sidebarElements: null,
};

export default SspSidebarLayout;
