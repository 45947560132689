import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import CountrySpecification from "../../components/countrySpecification/countrySpecification";
import Divider from "../../components/divider/divider";
import HeadlineElement from "../../components/headlineElement/headlineElement";
import SEO from "../../components/seo";
import Shimmer from "../../components/shimmer/shimmer";
import withAuthentication from "../../components/ssp/authentication/withAuthentication";
import ProfileDataFormBuilder from "../../components/ssp/profileDataFormBuilder";
import SspLayout from "../../components/ssp/sspLayout/sspLayout";
import SspRegistry from "../../components/ssp/sspRegistry/sspRegistry";
import SspSidebarLayout from "../../components/ssp/sspSidebarLayout/sspSidebarLayout";
import SspTitle from "../../components/ssp/sspTitle/sspTitle";
import {
  useGetProfileContactQuery,
  useGetProfileInfoQuery,
  useGetStatusInfoQuery,
  useGetUserAddressQuery,
} from "../../features/io/ioSspApiSlice";
import { IconDualToneProfile48, IconEarth24, IconGermany24 } from "../../icons";
import { getUserInfo } from "../../services/sspAuthentication.service";
import { getRegState, hasRegStateError } from "../../utils/getRegState";
import getTaskKey from "../../utils/getTaskKey";
import getUserRole, {
  ROLE_DONOR,
  ROLE_KIT_REQUESTER,
  ROLE_PAUSED,
  ROLE_USER,
} from "../../utils/getUserRole";
import KontentItemEyecatcherSidebar from "../kontentItemEyecatcherSidebar";

const KontentItemSspProfile = ({ data: { page } }) => {
  const intl = useIntl();

  const userInfo = getUserInfo();

  const statusInfo = useGetStatusInfoQuery();
  const userAddress = useGetUserAddressQuery();
  const profileInfo = useGetProfileInfoQuery();
  const profileContact = useGetProfileContactQuery();

  const role = getUserRole(userInfo);
  const regStatus = getRegState(statusInfo?.data, role);
  const taskKey = getTaskKey(role, regStatus);

  const shimmer = [];

  for (let i = 0; i < 6; i += 1) {
    shimmer.push(
      <div className="d-flex flex-column">
        <Shimmer variant="headline" />
        <Shimmer variant="text" />
        <Divider />
      </div>,
    );
  }

  const availableStatusWorld = {
    [ROLE_DONOR]: { status: "active", text: page.elements.label_availability_active.value },
    [ROLE_KIT_REQUESTER]: { status: "progress", text: page.elements.label_availability_in_progress.value },
    [ROLE_PAUSED]: { status: "paused", text: page.elements.label_availability_paused.value },
  };

  const availableRegistry = {
    [ROLE_DONOR]: { status: "active", text: page.elements.label_registry_active.value },
    [ROLE_KIT_REQUESTER]: { status: "progress", text: page.elements.label_registry_in_progress.value },
  };

  const errorRegistrationProcess = hasRegStateError(regStatus);

  const loading = statusInfo.isLoading
    || profileInfo.isLoading
    || profileContact.isLoading
    || userAddress.isLoading;

  const seperateUserInfo = profileInfo.data
    ? {}
    : { firstname: userInfo?.fn, lastname: userInfo?.ln };

  return (
    <SspLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SspTitle
        headline={intl.formatMessage({ id: "ssp.my_data" })}
        icon={<IconDualToneProfile48 />}
      />

      {loading ? (
        <Row className="ssp-profile">
          <Col xs={7}>
            <HeadlineElement title={page.elements.headline_contact_data.value}>
              <Shimmer variant="text" />
            </HeadlineElement>
            {shimmer}
          </Col>
        </Row>
      ) : (
        <SspSidebarLayout sidebarElements={role !== ROLE_USER
          ? (
            <>
              <SspRegistry
                header={(
                  <HeadlineElement title={page.elements.headline_availability.value}>
                    {page.elements.description_availability.value}
                  </HeadlineElement>
                )}
              >
                <CountrySpecification
                  icon={<IconGermany24 />}
                  label={intl.formatMessage({ id: "ssp_profile.dkms_donorfile" })}
                  status={errorRegistrationProcess
                    ? "error"
                    : availableRegistry[role].status}
                  statusText={errorRegistrationProcess
                    ? intl.formatMessage({ id: "country_specification.state_error" })
                    : availableRegistry[role].text}
                />
                <CountrySpecification
                  icon={<IconEarth24 />}
                  label={intl.formatMessage({ id: "ssp_profile.world_wide_donor" })}
                  status={errorRegistrationProcess
                    ? "error"
                    : availableStatusWorld[role].status}
                  statusText={errorRegistrationProcess
                    ? intl.formatMessage({ id: "country_specification.state_error" })
                    : availableStatusWorld[role].text}
                />
              </SspRegistry>

              <KontentItemEyecatcherSidebar
                data={page.elements.eyecatcher_sidebar}
                apiRole={role}
                taskKey={taskKey}
              />
            </>
          )
          : null}
        >
          <ProfileDataFormBuilder
            profileInfo={{
              ...userAddress.data,
              ...profileInfo.data,
              ...profileContact.data,
              ...seperateUserInfo,
            }}
            header={(
              <HeadlineElement title={page.elements.headline_contact_data.value}>
                {role !== ROLE_USER ? page.elements.description_contact_data.value : null}
              </HeadlineElement>
            )}
            accountSettingsHref="#"
            isNotRegistered={role !== ROLE_DONOR && role !== ROLE_KIT_REQUESTER}
            apiRole={role}
            taskKey={taskKey}
          />
        </SspSidebarLayout>
      )}
    </SspLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemSspProfile(id: {eq: $id}) {
      id
      elements {
      metadata__meta_description {
          value
        }
        metadata__meta_title {
          value
        }
        metadata__og_description {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_title {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_title {
          value
        }
        description_availability {
          value
        }
        description_contact_data {
          value
        }
        headline_availability {
          value
        }
        headline_contact_data {
          value
        }
        eyecatcher_sidebar {
          value {
            ...KontentItemEyecatcherSidebar
          }
        }
        label_availability_active {
          value
        }
        label_availability_in_progress {
          value
        }
        label_availability_paused {
          value
        }
        label_registry_active {
          value
        }
        label_registry_in_progress {
          value
        }
      }
    }
  }
`;

KontentItemSspProfile.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withAuthentication(KontentItemSspProfile);
