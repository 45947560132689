import {
  STATE_ERROR,
  STATE_ORDERED,
  STATE_REGISTERED,
  STATE_RETURNED,
  STATE_SHIPPED,
} from "./getRegState";
import { ROLE_DONOR, ROLE_KIT_REQUESTER } from "./getUserRole";

export const TASKKEY_PLACEHOLDER = "placeholder";
export const TASKKEY_CREATED = "created";
export const TASKKEY_AVAILABLE = "available";
export const TASKKEY_REQUESTED = "requested";
export const TASKKEY_REGISTERED = "registered";
export const TASKKEY_SAMPLE_PROVIDED = "sample_provided";
export const TASKKEY_SWABKIT_ORDERED = "swabkit_ordered";
export const TASKKEY_SAMPLE_LABORATORY_ENTRY = "sample_laboratory_entry";

const getTaskKey = (role, regStatus) => {
  switch (role) {
    case ROLE_DONOR:
      return TASKKEY_REGISTERED;
    case ROLE_KIT_REQUESTER:
      switch (regStatus) {
        case STATE_REGISTERED:
          return TASKKEY_SWABKIT_ORDERED;
        case STATE_RETURNED:
          return TASKKEY_SAMPLE_LABORATORY_ENTRY;
        case STATE_SHIPPED:
          return TASKKEY_SAMPLE_PROVIDED;
        case STATE_ORDERED:
          return TASKKEY_SWABKIT_ORDERED;
        default:
          return STATE_ERROR;
      }
    default:
      return TASKKEY_REQUESTED;
  }
};

export default getTaskKey;
