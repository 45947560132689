import React from "react";

import { useFormikContext } from "formik";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setIsEditing } from "../../../features/sspSlice";
import {
  IconAccordionExpand18,
  IconClose18,
  IconEdit18,
  IconExternalLink18,
} from "../../../icons";
import LinkButton from "../../button/linkButton";

import "./sspProfileDataElement.scss";

const SspProfileDataElement = (props) => {
  const {
    label,
    data,
    isLocked,
    form,
    id,
    accountSettingsHref,
    additionalContent,
    labelIcon,
    isNotRegistered,
    error,
  } = props;

  const isEditing = useSelector((state) => state.ssp.isEditing);
  const dispatch = useDispatch();
  const formik = useFormikContext();

  let actionButton = null;

  const generateDataView = () => {
    let formattedData;
    switch (id) {
      case "private_address":
        formattedData = (
          <>
            <div>{data.street} {data.houseno}</div>
            {(data.apartment || data.co) && <div>{data.apartment} {data.co}</div>}
            <div>{data.zipcode} {data.city}</div>
          </>
        );
        break;
      case "phone":
        formattedData = (
          <>
            <div>{data.mobile}</div>
            <div>{data.alternatePhone}</div>
          </>
        );
        break;
      default:
        formattedData = Object.values(data).join(" ").trim();
    }

    formattedData = formattedData || (
      <div className="text-grey-dark">
        <FormattedMessage id="profile.value_not_given" />
      </div>
    );

    return formattedData;
  };

  if (isLocked) {
    actionButton = (
      <LinkButton
        href={accountSettingsHref}
        variant="text-button-small"
        className="text-primary"
      >
        <FormattedMessage id="datablock.reset_password" /> <IconExternalLink18 />
      </LinkButton>
    );
  } else if (!isNotRegistered && !error) {
    if (isEditing.indexOf(id) === -1) {
      actionButton = Object.values(data).filter((item) => (item === "" || typeof item === "undefined")).length === Object.keys(data).length
        ? (
          <LinkButton
            onClick={() => {
              dispatch(
                setIsEditing(id),
              );
            }}
            variant="text-button-small"
            className="text-primary"
          >
            <IconAccordionExpand18 /> <FormattedMessage id="datablock.add" />
          </LinkButton>
        )
        : (
          <LinkButton
            onClick={() => {
              formik.validateForm()
                .then((errors) => formik.setTouched({ ...formik.touched, ...errors }));
              dispatch(
                setIsEditing(id),
              );
            }}
            variant="text-button-small"
            className="text-primary"
          >
            <IconEdit18 /> <FormattedMessage id="datablock.edit" />
          </LinkButton>
        );
    } else {
      actionButton = (
        <LinkButton
          onClick={() => {
            dispatch(
              setIsEditing(id),
            );
          }}
          variant="text-button-small"
          className="text-primary"
        >
          <IconClose18 /> <FormattedMessage id="datablock.discard" />
        </LinkButton>
      );
    }
  }

  if (isEditing.indexOf(id) !== -1) {
    return (
      <div id={id} className="ssp-profile-data-element ssp-profile-data-element--editing">
        <div className="d-block d-sm-flex justify-content-sm-between">
          <div className="ssp-profile-data-element__label">{label} {labelIcon}</div>
          {actionButton}
        </div>
        {additionalContent && (
          <div className="ssp-profile-data-element__additional-content">{additionalContent}</div>
        )}
        <div className="ssp-profile-data-element__content">{form}</div>
      </div>
    );
  }

  return (
    <div id={id} className="ssp-profile-data-element">
      <div className="ssp-profile-data-element__label">
        {label} {labelIcon}
        <div className="ssp-profile-data-element__content">{generateDataView()}</div>
      </div>
      {actionButton}
    </div>
  );
};

SspProfileDataElement.propTypes = {
  accountSettingsHref: PropTypes.string,
  additionalContent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.object]),
  error: PropTypes.bool,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  isNotRegistered: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  labelIcon: PropTypes.oneOfType([PropTypes.object]),
};

SspProfileDataElement.defaultProps = {
  accountSettingsHref: null,
  additionalContent: null,
  data: null,
  error: false,
  isLocked: false,
  labelIcon: null,
};

export default SspProfileDataElement;
