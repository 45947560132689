import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemLocationsSection from "../kontentItemLocationsSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import TabNavigationGenerator from "../virtualDrive/tabNavigationGenerator";

const KontentItemSubAreaPage = ({ data }) => (
  <MainLayout>

    <SEO
      title={data.page.elements.metadata__meta_title.value}
      description={data.page.elements.metadata__meta_description.value}
      twitterTitle={data.page.elements.metadata__twitter_title.value}
      twitterSite={data.page.elements.metadata__twitter_site.value}
      twitterImage={data.page.elements.metadata__twitter_image.value}
      twitterDescription={data.page.elements.metadata__twitter_description.value}
      twitterCreator={data.page.elements.metadata__twitter_creator.value}
      ogTitle={data.page.elements.metadata__og_title.value}
      ogImage={data.page.elements.metadata__og_image.value}
      ogDescription={data.page.elements.metadata__og_description.value}
      isHiddenForRobots={data.page.elements.meta_robots__noindex.value.length > 0}
    />

    <SubNavigationGenerator id={data.page.id} />

    <HeroHeadline
      imageData={data.page.elements.hero_image}
      tabNavigation={<TabNavigationGenerator id={data.page.id} />}
    >
      {data.page.elements.hero_headline.value}
    </HeroHeadline>

    <BreadcrumbGenerator id={data.page.id} />

    <KontentItemLocationsSection data={data.page.elements.location_list} />

    <RichText data={data.page.elements.rich_text} isArticle />

    {data.page.elements.page_sections.value.map((item) => mapKontentItem(item))}

  </MainLayout>
);

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
          url_slug {
              value
          }
          title {
              value
          }
      }
    }
    page: kontentItemSubAreaPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        meta_robots__noindex {
          value {
            codename
          }
        }
        hero_headline {
          value
        }
        hero_image{
          value {
            ...KontentItemImage
          }
        }
        location_list {
          value {
            ...KontentItemLocationsSection
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemImageCardSliderSection
            ...KontentItemProgramSliderSection
            ...KontentItemEyecatcherImage
            ...KontentItemEyecatcher
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemFaqSection
            ...KontentItemRegistryBanner
            ...KontentItemRegistrationProcessSection
            ...KontentItemMoneyPartition
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadSection
            ...KontentItemStatementboxGroup
          }
        }
        rich_text {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcherImage
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemProcessStepGroup
            ...KontentItemCtaButton
            ...KontentItemStatementboxGroup
            ...KontentItemEmbedExternal
          }
        }
      }
    }
  }
`;

KontentItemSubAreaPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemSubAreaPage;
