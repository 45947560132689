import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import HeadlineElement from "../../components/headlineElement/headlineElement";
import QuickLinks from "../../components/quickLinks/quickLinks";
import RegistrationStatus from "../../components/registrationStatus/registrationStatus";
import SEO from "../../components/seo";
import Shimmer from "../../components/shimmer/shimmer";
import withAuthentication from "../../components/ssp/authentication/withAuthentication";
import Avatar from "../../components/ssp/avatar/avatar";
import SspLayout from "../../components/ssp/sspLayout/sspLayout";
import SspSidebarLayout from "../../components/ssp/sspSidebarLayout/sspSidebarLayout";
import SspTitle from "../../components/ssp/sspTitle/sspTitle";
import StatusBox from "../../components/statusBox/statusBox";
import Wallet from "../../components/wallet/wallet";
import {
  useGetProfileContactQuery,
  useGetProfileInfoQuery,
  useGetStatusInfoQuery,
  useGetUserAddressQuery,
} from "../../features/io/ioSspApiSlice";
import { getUserInfo } from "../../services/sspAuthentication.service";
import { getRegState, hasRegStateError } from "../../utils/getRegState";
import getTaskKey from "../../utils/getTaskKey";
import getUserRole, { ROLE_DONOR, ROLE_KIT_REQUESTER, ROLE_USER } from "../../utils/getUserRole";
import KontentItemSspRole from "../kontentItemSspRole";
import kontentItemSspStatusError from "../kontentItemSspStatusError";
import QuickLinkTiles from "../press/quickLinkTiles";

const KontentItemSspDashboard = ({ data: { page } }) => {
  const userInfo = getUserInfo();

  const role = getUserRole(userInfo);

  const statusInfo = useGetStatusInfoQuery();

  let userAddress = null;
  let profileInfo = null;
  let profileContact = null;

  if (role === ROLE_DONOR) {
    userAddress = useGetUserAddressQuery();
    profileInfo = useGetProfileInfoQuery();
    profileContact = useGetProfileContactQuery();
  }

  const regStatus = getRegState(statusInfo?.data, role);
  const taskKey = getTaskKey(role, regStatus);

  const errorRegistrationProcess = hasRegStateError(regStatus);

  const errors = {
    [ROLE_DONOR]: page.elements.error_donor,
    [ROLE_KIT_REQUESTER]: page.elements.error_requester,
  };

  const kenticoRoles = {
    [ROLE_DONOR]: page.elements.role_registered,
    [ROLE_KIT_REQUESTER]: page.elements.role_registration_in_progress,
    [ROLE_USER]: page.elements.role_not_registered_pending,
  };

  const roleObject = KontentItemSspRole({
    activeTask: taskKey,
    apiRole: role,
    data: kenticoRoles[role],
    regStatus,
    user: {
      address: userAddress?.data,
      donorId: profileInfo?.data?.donor_id || "",
      email: profileContact?.data?.email,
      mobile: profileContact?.data?.mobile,
      name: `${profileInfo?.data?.firstname} ${profileInfo?.data?.lastname}`,
      phone: profileContact?.data?.phone,
    },
  });

  const activeTask = roleObject.statusTasks[taskKey];

  return (
    <SspLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SspTitle
        headline={`Willkommen, ${userInfo?.fn}!`}
        icon={(
          <Avatar
            name={userInfo?.fn}
          />
        )}
      />

      {statusInfo.isLoading ? (
        <div className="ssp-dashboard">
          <HeadlineElement title={page.elements.headline.value} />
          <Shimmer variant="headline" />
          <Shimmer className="shimmer--text" width={339} height={48} />
          <Shimmer width={582} height={240} />
        </div>
      ) : (
        <>
          <SspSidebarLayout
            dashboard
            sidebarElements={activeTask?.furtherLinks.length > 0
              ? [
                <HeadlineElement key="item1" title={page.elements.headline_further_links.value} />,
                <Row key="item2">
                  {activeTask?.furtherLinks.map((link) => (
                    <Col key={link.key} md={6} lg={12}>
                      {link}
                    </Col>
                  ))}
                </Row>,
              ]
              : null}
            areas={(
              <>
                <HeadlineElement title={page.elements.headline_areas.value} />
                <QuickLinks ssp>
                  {QuickLinkTiles({ data: page.elements.areas, variant: "ssp" })}
                </QuickLinks>
              </>
            )}
          >
            <HeadlineElement title={page.elements.headline.value} />
            <RegistrationStatus
              headline={roleObject.role}
              icon={roleObject.icon}
              description={roleObject.description}
              variant={role}
            />

            <StatusBox
              donorCard={roleObject.donorcard}
              statusTasks={role !== ROLE_DONOR
                ? [Object.values(roleObject.statusTasks).map((task) => (task?.component))]
                : [roleObject.statusTasks.address, roleObject.statusTasks.personalContactData]}
              buttons={activeTask?.buttons}
              description={activeTask?.description}
              errorData={role !== ROLE_USER && kontentItemSspStatusError({ data: errors[role] })}
              errorState={errorRegistrationProcess}
              addToWallet={(
                <Wallet
                  imgButton={roleObject.AddToWalletButton}
                  modal={roleObject.modal}
                />
                )}
            />

          </SspSidebarLayout>
        </>
      )}
    </SspLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemSspDashboard(id: {eq: $id}) {
      id
      elements {
      metadata__meta_description {
          value
        }
        metadata__meta_title {
          value
        }
        metadata__og_description {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_title {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_title {
          value
        }
        headline {
          value
        }
        headline_further_links {
          value
        }
        headline_areas {
          value
        }
        areas {
          value {
            ...QuickLinkTiles
          }
        }
        role_not_registered_pending {
          value {
            ...KontentItemSspRole
          }
        }
        role_registered {
          value {
            ...KontentItemSspRole
          }
        }
        role_registration_in_progress {
          value {
            ...KontentItemSspRole
          }
        }
        error_donor {
          value {
            ...KontentItemSspStatusError
          }
        }
        error_requester {
          value {
            ...KontentItemSspStatusError
          }
        }
      }
    }
  }
`;

KontentItemSspDashboard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withAuthentication(KontentItemSspDashboard);
