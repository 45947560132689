import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemCtaButton from "./kontentItemCtaButton";
import KontentItemImageLinkTiles from "./kontentItemImageLinkTiles";
import SimpleRichText from "./text/simpleRichText";
import StatusTask from "../components/statusTask/statusTask";
import { IconSuccessFilled24, IconTime24 } from "../icons";

const KontentItemStatusTask = (props) => {
  const {
    data,
    success,
    apiRole,
    taskKey,
  } = props;

  const task = data.value ? data.value[0] : data;

  if (!task || !task.elements) {
    return null;
  }

  const buttons = [];

  task.elements.buttons.value.forEach((button) => {
    buttons.push(<KontentItemCtaButton key={button.id} data={button} />);
  });

  return ({
    buttons,
    component: <StatusTask
      icon={success ? <IconSuccessFilled24 /> : <IconTime24 className="text-grey-dark" />}
      description={task.elements.label.value}
    />,
    description: <SimpleRichText data={task.elements.description} />,
    furtherLinks: KontentItemImageLinkTiles({
      apiRole,
      data: task.elements.items_further_links,
      taskKey,
    }),
  }
  );
};

export const query = graphql`
  fragment KontentItemStatusTask on kontent_item_statustask {
    id
    system {
      codename
    }
    elements {
      buttons {
        value {
          ...KontentItemCtaButton
        }
      }
      label {
        value
      }
      description {
        value
      }
      items_further_links {
        value {
          ...KontentItemImageLinkTiles
        }
      }
    }
  } 
`;

KontentItemStatusTask.propTypes = {
  apiRole: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  success: PropTypes.bool,
  taskKey: PropTypes.string.isRequired,
};

KontentItemStatusTask.defaultProps = {
  success: false,
};

export default KontentItemStatusTask;
